<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .spacer {
    flex: 1;
  }

  .toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #1976d2;
    color: white;
    font-weight: 600;
  }

  .toolbar img {
    margin: 0 16px;
  }

  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }


  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
  }

  .card {
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 40px;
    width: 200px;
    margin: 0 8px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
  }

  .card-container .card:not(:last-child) {
    margin-right: 0;
  }

  .card.card-small {
    height: 16px;
    width: 168px;
  }

  .card-container .card:not(.highlight-card) {
    cursor: pointer;
  }

  .card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }

  .card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card.highlight-card {
    background-color: #1976d2;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
  }

  .card.card.highlight-card span {
    margin-left: 60px;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  .circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
  }

  .circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }


  .emailLogo {
    border-radius: 0px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 16px;
    width: 60px;
    margin: 4px 4px 4px 4px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 16px;
  }
  .title {
    border-radius: 0px;
    border: 0px solid #eee;
    background-color: #1976d2;
    height: 20px;
    width: 200px;
    margin: 16px 16px 16px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 16px;
    font-size: 30px;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .card-container>*:not(.circle-link),
    .terminal {
      width: 100%;
    }

    .card:not(.highlight-card) {
      height: 16px;
      margin: 8px 0;
    }

    .card.highlight-card span {
      margin-left: 72px;
    }

    svg#rocket-smoke {
      right: 120px;
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 575px) {
    svg#rocket-smoke {
      display: none;
      visibility: hidden;
    }
  }
</style>

<!-- Toolbar -->
<div class="toolbar" role="banner">
  <span class="title">mons-bonn.de</span>
  <div class="spacer"></div>  

  <div class="card-container">
    <a class="emailLogo" target="_blank" rel="noopener" href="mailto:email@mons-bonn.de">
      <span class="material-icons" width="16" height="16" viewBox="0 0 16 16">email</span>
      <span>Mail</span>
    </a>

  </div>

</div>

<div class="content" role="main">
  <h1>mons-bonn.de</h1>

  <h2>Hier sind ein paar Projekte:</h2>

  <div class="card-container">
    <a class="card" target="_blank" rel="noopener" href="https://vegan.mons-bonn.de">
      <span class="material-icons" width="24" height="24" viewBox="0 0 24 24">restaurant</span>
      <span>Vegan</span>
    </a>
    <a class="card" target="_blank" rel="noopener" href="https://maps.sensor.community/#15/50.7459/7.0716">
      <span class="material-icons" width="24" height="24" viewBox="0 0 24 24">cloud_queue</span>
      <span>Dicke Luft in Bonn?</span>
    </a>
    <a class="card" target="_blank" rel="noopener" href="https://jonahmons.github.io/trashApp/">
      <span class="material-icons" width="24" height="24" viewBox="0 0 24 24">cleaning_services</span>
      <span>Clean your City</span>
    </a>

  </div>

  <router-outlet></router-outlet>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
